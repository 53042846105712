<template>
  <section id="bot-message-validation" class="bot-message-validation">
    <div class="bot-message-validation__check">
      <vs-checkbox
        color="primary"
        v-model="botValidationStatus"
        @change="openValidationPopup"
      >
        {{
          lang.botMaker.botMessageValidation.validateMessage[languageSelected]
        }}
        <span v-if="validationName"> ({{ validationName }}) </span>
      </vs-checkbox>
      <feather-icon
        icon="SettingsIcon"
        svgClasses="h-4 w-4 mt-1"
        class="cursor-pointer hover:text-primary"
        v-show="botValidationStatus"
        @click="openValidationPopup"
      />

      <KonaHelpTooltip
        :text="lang.botMaker.botMessageValidation.tooltip[languageSelected]"
        position="right"
        class="help"
      />
    </div>

    <KonaPopup
      ref="botUpdateValidationPopup"
      :title="
        lang.botMaker.botMessageValidation.form.validationUpdate.title[
          languageSelected
        ]
      "
      :onAccept="saveValidationUpdate"
      :onCancel="cancelValidationUpdate"
      :showActions="showMainPopupActions"
    >
      <div class="validation-setup" v-if="!jumpPopupActive">
        <span class="mt-4">
          {{
            lang.botMaker.botMessageValidation.form.validationUpdate.text[
              languageSelected
            ]
          }}
        </span>
        <ul class="mt-4 mb-4">
          <li>
            <vs-radio
              v-model="validationUpdate"
              vs-name="update"
              vs-value="update"
            >
              {{
                lang.botMaker.botMessageValidation.form.validationUpdate
                  .option1[languageSelected]
              }}
            </vs-radio>
          </li>
          <li>
            <vs-radio
              v-model="validationUpdate"
              vs-name="create"
              vs-value="create"
            >
              {{
                lang.botMaker.botMessageValidation.form.validationUpdate
                  .option2[languageSelected]
              }}
            </vs-radio>
          </li>
        </ul>
      </div>

      <div class="validation-jump" v-else>
        <KonaTree
          v-if="jumpPopupActive"
          :tpl="'basic'"
          :key="keyRefresh"
          :tree-data="treeData"
          :searchBar="true"
          :showJump="false"
          :halfcheck="false"
          :multiple="false"
          :radio="true"
          :canMove="false"
          :serachWidht="'w-1/2'"
          :marginTop="'mt-2'"
          @clickNode="clickNode"
          ref="konaTree"
        />

        <div class="jumpFooter">
          <vs-divider />
          <vs-row
            vs-align="center"
            vs-type="flex"
            vs-justify="space-around"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="closeJumpPopup" color="danger" type="flat">
                {{ lang.botMaker.integrations.cancel[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="deleteJump" color="danger">
                <!-- :disabled="!jumpDialogId" -->
                {{ lang.botMaker.jump.remove.title[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="updateJump" :disabled="!jumpDialog">
                {{ lang.botMaker.jump.update.title[languageSelected] }}
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </KonaPopup>

    <KonaPopup
      ref="botMessageValidationPopup"
      :title="
        lang.botMaker.botMessageValidation.validateMessage[languageSelected]
      "
      :button-close-hidden="true"
      :validate="validate"
      :onAccept="saveValidation"
      :onCancel="cancelValidation"
      :showActions="showMainPopupActions"
      class="bot-message-validation__form"
    >
      <div class="validation-setup" v-if="!jumpPopupActive">
        <!-- validation name -->
        <section class="form-group">
          <vs-input
            v-model="botMessageValidation.service.name"
            :label="
              lang.botMaker.botMessageValidation.form.name.label[
                languageSelected
              ]
            "
            name="name"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.first('name') }"
          />
          <span class="error-msg" v-if="errors.first('name')">
            {{
              lang.botMaker.botMessageValidation.form.name.error[
                languageSelected
              ]
            }}
          </span>
        </section>

        <!-- validation question -->
        <section class="form-group">
          <vs-input
            v-model="botMessageValidation.question"
            class="w-full"
            :label="
              lang.botMaker.botMessageValidation.form.question.label[
                languageSelected
              ]
            "
            name="question"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.first('question') }"
          />
          <span class="error-msg" v-if="errors.first('question')">
            {{
              lang.botMaker.botMessageValidation.form.question.error[
                languageSelected
              ]
            }}
          </span>
        </section>

        <!-- validation answers -->
        <section class="form-group mt-4">
          <h2>
            {{
              lang.botMaker.botMessageValidation.form.answers.title[
                languageSelected
              ]
            }}
          </h2>

          <table class="answers-table w-full">
            <!-- prettier-ignore -->
            <tr>
            <th>{{ lang.botMaker.botMessageValidation.form.answers.table.answer[languageSelected ] }}</th>
            <th>{{ lang.botMaker.botMessageValidation.form.answers.table.showOption[languageSelected] }}</th>
            <th>{{ lang.botMaker.botMessageValidation.form.answers.table.action[languageSelected] }}</th>
            <th></th>
          </tr>
            <!-- prettier-ignore -->
            <!-- positive -->
            <tr>
              <td class="w-1/5">
                {{
                  lang.botMaker.botMessageValidation.form.answers.table
                    .positive[languageSelected]
                }}
              </td>
              <td class="w-1/5">
                <multiselect
                  v-model="botMessageValidation.positive.text"
                  :allow-empty="false"
                  :options="positiveAnswers"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                />
              </td>
              <td class="w-2/5">
                <multiselect
                  :allow-empty="true"
                  v-model="botMessageValidation.positive.actions[0]"
                  :options="getAvailableActions()"
                  label="name"
                  track-by="key"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :placeholder="
                    lang.botMaker.botMessageValidation.form.answers.table
                      .actionsPlaceholder[languageSelected]
                  "
                  @input="onActionSelect('positive')"
                  :class="{ 'is-invalid': submitted && invalidPositiveKey }"
                />
              </td>
              <td class="w-1/5">
                <vs-input
                  v-if="
                    botMessageValidation.positive.actions[0] &&
                      botMessageValidation.positive.actions[0].key ===
                        'responseText'
                  "
                  v-model="botMessageValidation.positive.actions[0].args[0]"
                  class="w-full"
                  :class="{
                    'is-invalid':
                      submitted && !invalidPositiveKey && invalidPositiveArgs
                  }"
                />
                <span
                  v-if="
                    botMessageValidation.positive.actions[0] &&
                      botMessageValidation.positive.actions[0].key === 'jump' &&
                      botMessageValidation.positive.actions[0].args[0] !== ''
                  "
                >
                  {{
                    dialogsMap[
                      botMessageValidation.positive.actions[0].args[0]
                    ] &&
                      dialogsMap[
                        botMessageValidation.positive.actions[0].args[0]
                      ].name
                  }}
                </span>
              </td>
            </tr>
            <!-- positive error messages -->
            <tr>
              <td></td>
              <td></td>
              <td>
                <span class="error-msg" v-if="submitted && invalidPositiveKey">
                  {{
                    lang.botMaker.botMessageValidation.form.answers.table
                      .actionsErrorMsg.key[languageSelected]
                  }}
                </span>
              </td>
              <td></td>
            </tr>
            <!-- negative -->
            <tr>
              <td class="w-1/5">
                {{
                  lang.botMaker.botMessageValidation.form.answers.table
                    .negative[languageSelected]
                }}
              </td>
              <td class="w-1/5">
                <multiselect
                  v-model="botMessageValidation.negative.text"
                  :allow-empty="false"
                  :options="negativeAnswers"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                />
              </td>
              <td class="w-2/5">
                <multiselect
                  :allow-empty="true"
                  v-model="botMessageValidation.negative.actions[0]"
                  :options="getAvailableActions()"
                  label="name"
                  track-by="key"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :placeholder="
                    lang.botMaker.botMessageValidation.form.answers.table
                      .actionsPlaceholder[languageSelected]
                  "
                  @input="onActionSelect('negative')"
                  :class="{ 'is-invalid': submitted && invalidNegativeKey }"
                />
              </td>
              <td class="w-1/5">
                <vs-input
                  v-if="
                    botMessageValidation.negative.actions[0] &&
                      botMessageValidation.negative.actions[0].key ===
                        'responseText'
                  "
                  v-model="botMessageValidation.negative.actions[0].args[0]"
                  class="w-full"
                  :class="{
                    'is-invalid':
                      submitted && !invalidNegativeKey && invalidNegativeArgs
                  }"
                />
                <span
                  v-if="
                    botMessageValidation.negative.actions[0] &&
                      botMessageValidation.negative.actions[0].key === 'jump' &&
                      botMessageValidation.negative.actions[0].args[0] !== ''
                  "
                >
                  {{
                    dialogsMap[
                      botMessageValidation.negative.actions[0].args[0]
                    ] &&
                      dialogsMap[
                        botMessageValidation.negative.actions[0].args[0]
                      ].name
                  }}
                </span>
              </td>
            </tr>
            <!-- negative error messages -->
            <tr>
              <td></td>
              <td></td>
              <td>
                <span class="error-msg" v-if="submitted && invalidNegativeKey">
                  {{
                    lang.botMaker.botMessageValidation.form.answers.table
                      .actionsErrorMsg.key[languageSelected]
                  }}
                </span>
              </td>
              <td></td>
            </tr>
          </table>
        </section>

        <!-- ask again -->
        <section class="form-group mt-4">
          <h2>
            {{
              lang.botMaker.botMessageValidation.form.repeatTimes.title[
                languageSelected
              ]
            }}
          </h2>

          <div class="ask-again">
            <div>
              <vs-input
                type="number"
                v-model.number="botMessageValidation.askAgain"
                min="0"
                name="askAgain"
                v-validate="'required'"
                :class="{ 'is-invalid': submitted && errors.first('askAgain') }"
              />
              <span class="ml-2">
                {{
                  lang.botMaker.botMessageValidation.form.repeatTimes.times[
                    languageSelected
                  ]
                }}
              </span>
            </div>
            <span
              class="error-msg"
              v-if="submitted && errors.first('askAgain')"
            >
              {{
                lang.botMaker.botMessageValidation.form.name.error[
                  languageSelected
                ]
              }}
            </span>
          </div>
        </section>

        <!-- TODO: revert 1521 once its working fine -->
        <!-- <section class="form-group mt-4">
        <span class="flex">
          <vx-slider color="success" v-model="applyAll" class="mr-2" />
          {{
            lang.botMaker.botMessageValidation.form.applyAll[languageSelected]
          }}
        </span>
      </section> -->
        <!-- TODO: revert 1521 once its working fine -->
      </div>

      <div class="validation-jump" v-else>
        <KonaTree
          v-if="jumpPopupActive"
          :tpl="'basic'"
          :key="keyRefresh"
          :tree-data="treeData"
          :searchBar="true"
          :showJump="false"
          :halfcheck="false"
          :multiple="false"
          :radio="true"
          :canMove="false"
          :serachWidht="'w-1/2'"
          :marginTop="'mt-2'"
          @clickNode="clickNode"
          ref="konaTree"
        />

        <div class="jumpFooter">
          <vs-divider />
          <vs-row
            vs-align="center"
            vs-type="flex"
            vs-justify="space-around"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="closeJumpPopup" color="danger" type="flat">
                {{ lang.botMaker.integrations.cancel[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="deleteJump" color="danger">
                <!-- :disabled="!jumpDialogId" -->
                {{ lang.botMaker.jump.remove.title[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="updateJump" :disabled="!jumpDialog">
                {{ lang.botMaker.jump.update.title[languageSelected] }}
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </KonaPopup>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import botTreeMixin from '@/mixins/botTreeMixin'

export default {
  name: 'BotMessageValidation',
  data() {
    return {
      jumpPopupActive: false,
      keyRefresh: new Date().getTime(),
      responseType: null,
      previousAction: null,
      jumpDialog: null,
      validation: {},
      actions: [],
      validAnswers: true,
      submitted: false,
      showMainPopupActions: true
    }
  },
  watch: {
    jumpPopupActive(newVal) {
      if (newVal == true) {
        this.keyRefresh = new Date().getTime()
      }
    },
    botLanguage() {
      this.botMessageValidation.positive.text = this.positiveAnswers[0]
      this.botMessageValidation.negative.text = this.negativeAnswers[0]
    }
  },
  mixins: [botTreeMixin],
  components: {
    KonaPopup: () => import('@/components/KonaPopup.vue'),
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue'),
    KonaTree: () => import('@/components/KonaTree.vue'),
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botValidationEnabled',
      'botMessageValidation',
      'botMessageValidationApplyAll',
      'responseValidations',
      'botMessageInEdit',
      'dialogsMap',
      'validationUpdateMode'
    ]),
    ...mapGetters('bots', ['bot']),
    botLanguage() {
      return this.bot.nlu.culture.split('-')[0]
    },
    positiveAnswers() {
      return [
        this.lang.botMaker.botMessageValidation.form.answers.table
          .positiveOptions.option1[this.botLanguage],
        this.lang.botMaker.botMessageValidation.form.answers.table
          .positiveOptions.option3[this.botLanguage]
      ]
    },
    negativeAnswers() {
      return [
        this.lang.botMaker.botMessageValidation.form.answers.table
          .negativeOptions.option1[this.botLanguage],
        this.lang.botMaker.botMessageValidation.form.answers.table
          .negativeOptions.option3[this.botLanguage]
      ]
    },
    validationUpdate: {
      get() {
        return this.validationUpdateMode
      },
      set(val) {
        this.SET_VALIDATION_UPDATE_MODE(val)
      }
    },
    applyAll: {
      get() {
        return this.botMessageValidationApplyAll
      },
      set(val) {
        this.SET_VALIDATION_APPLY_ALL(val)
      }
    },
    botValidationStatus: {
      get() {
        return this.botValidationEnabled
      },
      set(val) {
        this.SET_VALIDATION_STATUS(val)
      }
    },
    validationName() {
      if (this.botMessageInEdit && this.botMessageInEdit.isSaved) {
        const validationSettings = this.dialogsMap[
          this.botMessageInEdit.dialogId
        ].sendValidationSettings
        let currentValidation = null
        if (validationSettings) {
          currentValidation = this.responseValidations.find(
            v => v._id === validationSettings.validation
          )
        }
        if (
          currentValidation &&
          currentValidation.service &&
          currentValidation.service.name
        ) {
          return currentValidation.service.name
        }
      }
      return null
    },
    invalidPositiveKey() {
      return (
        this.botMessageValidation.positive.actions[0] === [] ||
        this.botMessageValidation.positive.actions[0] === null ||
        (this.botMessageValidation.positive.actions[0] &&
          this.botMessageValidation.positive.actions[0].key === '')
      )
    },
    invalidPositiveArgs() {
      return (
        this.botMessageValidation.positive.actions[0] &&
        this.botMessageValidation.positive.actions[0].args.length === 0 &&
        this.botMessageValidation.positive.actions[0].key !== 'execTakeover'
      )
    },
    invalidNegativeKey() {
      return (
        this.botMessageValidation.negative.actions[0] === [] ||
        this.botMessageValidation.negative.actions[0] === null ||
        (this.botMessageValidation.negative.actions[0] &&
          this.botMessageValidation.negative.actions[0].key === '')
      )
    },
    invalidNegativeArgs() {
      return (
        this.botMessageValidation.negative.actions[0] &&
        this.botMessageValidation.negative.actions[0].args.length === 0 &&
        this.botMessageValidation.negative.actions[0].key !== 'execTakeover'
      )
    }
    // dialogJump() {
    //   return this.responseType &&
    //     this.botMessageValidation[this.responseType].actions[0].key === 'jump'
    //     ? this.botMessageValidation[this.responseType].actions[0].args[0]
    //     : null
    // }
  },
  methods: {
    ...mapMutations('botMaker', [
      'RESET_BOT_MESSAGE_VALIDATION',
      'SET_VALIDATION_APPLY_ALL',
      'SET_VALIDATION_STATUS',
      'SET_VALIDATION_UPDATE_MODE'
    ]),
    openValidationPopup() {
      if (this.botValidationStatus) {
        this.$validator.reset()
        this.SET_VALIDATION_UPDATE_MODE('create')
        this.$refs.botMessageValidationPopup.openPopup()
      }
    },
    normalizeVal(validation) {
      return {
        askAgain: validation.askAgain,
        answer: validation.answer,
        bot: validation.bot,
        question: validation.question,
        service: {
          name: validation.service.name
        },
        positive: {
          actions: [
            {
              key: validation.positive.actions[0]
                ? validation.positive.actions[0].key
                : '',
              args: validation.positive.actions[0]
                ? validation.positive.actions[0].args
                : []
            }
          ],
          text: validation.positive.text
        },
        negative: {
          actions: [
            {
              key: validation.negative.actions[0]
                ? validation.negative.actions[0].key
                : '',
              args: validation.negative.actions[0]
                ? validation.negative.actions[0].args
                : []
            }
          ],
          text: validation.negative.text
        }
      }
    },
    validateAnswers() {
      this.validAnswers =
        !this.invalidPositiveKey &&
        !this.invalidPositiveArgs &&
        !this.invalidNegativeKey &&
        !this.invalidNegativeArgs
    },
    async validate() {
      this.submitted = true
      this.validateAnswers()
      console.warn(this.botMessageValidation.positive.actions)
      const valid = (await this.$validator.validate()) && this.validAnswers
      if (valid) {
        if (this.botMessageInEdit.isSaved) {
          const validationSettings = this.dialogsMap[
            this.botMessageInEdit.dialogId
          ].sendValidationSettings

          let currentValidation = null
          if (validationSettings) {
            currentValidation = this.responseValidations.find(
              v => v._id === validationSettings.validation
            )
          }
          if (!currentValidation) return valid
          const sameValidations = this.lodash.isEqual(
            this.normalizeVal(currentValidation),
            this.normalizeVal(this.botMessageValidation)
          )
          if (!sameValidations) {
            this.SET_VALIDATION_UPDATE_MODE('update')
            this.$refs.botUpdateValidationPopup.openPopup()
            return false
          } else {
            return valid
          }
        } else {
          return valid
        }
      } else {
        // invalid form
        return valid
      }
    },
    async saveValidation() {
      if (
        this.botMessageValidation.positive.actions[0] &&
        this.botMessageValidation.positive.actions[0].key === ''
      ) {
        this.botMessageValidation.positive.actions = []
      }
      if (
        this.botMessageValidation.negative.actions[0] &&
        this.botMessageValidation.negative.actions[0].key === ''
      ) {
        this.botMessageValidation.negative.actions = []
      }
    },
    cancelValidation() {
      if (!this.botMessageInEdit.isSaved) {
        this.botValidationStatus = false
      }
      this.$validator.reset()
      this.submitted = false
    },
    saveValidationUpdate() {
      this.$refs.botMessageValidationPopup.closePopup()
    },
    cancelValidationUpdate() {
      /* cancelValidationUpdate */
    },
    async onActionSelect(responseType) {
      this.responseType = responseType
      const actionType = this.botMessageValidation[this.responseType].actions[0]
        ? this.botMessageValidation[this.responseType].actions[0].key
        : ''

      if (actionType === 'jump') {
        this.previousAction = this.botMessageValidation[
          this.responseType
        ].actions[0]
        await this.initTree()
        this.jumpPopupActive = true
        this.showMainPopupActions = false
      }
    },
    clickNode(node) {
      this.jumpDialog = node[0] || null
    },
    onCloseJumps() {
      this.$refs.konaTree.reset()
    },
    closeJumpPopup() {
      // when tree popup closed without saving, restore last selection
      this.botMessageValidation[
        this.responseType
      ].actions[0] = this.previousAction
      this.jumpPopupActive = false
      this.showMainPopupActions = true
    },
    updateJump() {
      this.botMessageValidation[this.responseType].actions[0] = {
        name: this.lang.botMaker.botMessageValidation.form.answers.table.jump[
          this.languageSelected
        ],
        key: 'jump',
        args: [this.jumpDialog.id]
      }
      this.jumpDialog = null
      this.jumpPopupActive = false
      this.showMainPopupActions = true
    },
    deleteJump() {
      /* deleteJump */
    },
    initValidation() {
      this.botMessageValidation.positive.text = this.positiveAnswers[0]
      this.botMessageValidation.negative.text = this.negativeAnswers[0]
    },
    getAvailableActions() {
      return [
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table
            .takeover[this.languageSelected],
          key: 'execTakeover',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.text[
            this.languageSelected
          ],
          key: 'responseText',
          args: []
        },
        {
          name: this.lang.botMaker.botMessageValidation.form.answers.table.jump[
            this.languageSelected
          ],
          key: 'jump',
          args: []
        }
      ]
    }
  },
  mounted() {
    this.initValidation()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.bot-message-validation {
  &__check {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-right: 10px;
    word-break: break-all;
    .current-validation {
      margin-left: 30px;
    }
    .help {
      right: -25px !important;
      top: -4px !important;
    }
  }
  &__form {
    .form-group {
      margin-bottom: 20px;
      h2 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .answers-table {
        tr {
          th {
            padding-left: 0;
            padding-right: 20px;
          }
          td:not(:first-of-type):not(:last-of-type) {
            padding-right: 10px;
          }
        }
      }
      .ask-again {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          align-items: center;
        }

        .vs-con-input-label {
          max-width: 50px;
        }
      }
    }
  }
}
</style>
